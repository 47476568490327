import Footer from "components/Footer";
import Navbar from "components/Navbar";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const ResourceDetails = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchResourceDetails = async () => {
      try {
        const response = await fetch(
          "https://yododigital.com/yo_do_admin/public/api/blog-details",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ id }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        if (data.success) {
          setBlog(data.data);
        }
      } catch (error) {
        console.error("Error fetching blog details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchResourceDetails();
  }, [id]);

  if (loading)
    return (
      <div className="text-white bg-[#11151c] min-h-screen w-full flex flex-col items-center justify-center text-white text-3xl text-center">
        Loading...
      </div>
    );
  if (!blog)
    return (
      <div className="text-white bg-[#11151c] flex min-h-screen w-full flex-col items-center justify-center text-white text-3xl text-center">
        Blog not found
      </div>
    );

  return (
    <>
      <Navbar transparent />

      <div className="bg-[#11151c] text-white p-6 shadow-lg min-h-screen flex flex-col items-center pt-40">
        <div className="max-w-3xl w-full">
          <div className="mb-8">
            {/* <h2 className="text-3xl font-bold  text-center">{blog.title}</h2> */}
            <p className="text-gray-400 text-center text-lg "> {blog.date}</p>
          </div>
          <div className="flex justify-start mb-8">
            <h2 className="text-3xl font-bold  text-center">{blog.title}</h2>
            {/* <p className="text-gray-400 text-center text-lg "> {blog.date}</p> */}
          </div>
          <img
            src={blog.image}
            alt={blog.title}
            className="w-full h-64 md:h-80 lg:h-96 object-cover mb-8 rounded-lg shadow-md"
          />
          <div className="text-lg leading-relaxed whitespace-pre-line">
            {blog.description}
          </div>
        </div>
      </div>
      <Footer />
    </>

  );
};

export default ResourceDetails;
