import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";
import "../views/Landing.css";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export default function Landing() {
  const video = process.env.PUBLIC_URL + "/background.mp4";
  const [clientCount, setClientCount] = useState(0);
  const [logos, setLogos] = useState([]);
  const [clientShoutOuts, setClientShoutOuts] = useState([]);
  const Checklist = process.env.PUBLIC_URL + "/Checklist-pana.png";
  const chooseus = process.env.PUBLIC_URL + "/chooseus.png";

  const history = useHistory();
  useEffect(() => {
    fetchClientCount();
    fetchLogos();
    fetchClientShoutOuts();
  }, []);

  
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const fetchClientCount = async () => {
    try {
      const response = await fetch(
        "https://yododigital.com/yo_do_admin/public/api/client-total"
      );
      const data = await response.json();
      if (data.success) {
        setClientCount(data.data.client);
      } else {
        throw new Error(data.message || "Failed to fetch client count");
      }
    } catch (error) {
      toast.error(`Error fetching client count: ${error.message}`);
    }
  };

  const fetchLogos = async () => {
    try {
      const response = await fetch(
        "https://yododigital.com/yo_do_admin/public/api/logo"
      );
      const data = await response.json();
      if (data.success) {
        setLogos(data.data);
      } else {
        throw new Error(data.message || "Failed to fetch logos");
      }
    } catch (error) {
      toast.error(`Error fetching logos: ${error.message}`);
    }
  };

  const fetchClientShoutOuts = async () => {
    try {
      const response = await fetch(
        "https://yododigital.com/yo_do_admin/public/api/testimonials"
      );
      const data = await response.json();
      if (data.success) {
        setClientShoutOuts(data.data);
      } else {
        throw new Error(data.message || "Failed to fetch client shout-outs");
      }
    } catch (error) {
      toast.error(`Error fetching client shout-outs: ${error.message}`);
    }
  };
  // Slider settings for client shout-outs
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-[#11151c]">
      <Navbar transparent />
      <main>
        <div
          className="relative pt-32 md:pt-16 pb-32 flex content-center items-center justify-center"
          style={{
            minHeight: "75vh",
          }}
        >
          <div className="absolute top-0 w-full h-full">
            <video
              autoPlay
              muted
              loop
              className="absolute top-0 w-full h-full object-cover"
              onError={(e) => console.error("Video playback error:", e)}
            >
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-25 bg-black"
            ></span>
          </div>

          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-full py-4 px-4 ml-auto mr-auto text-center">
                <div className="text-center">
                  <h1 className="text-white font-semibold text-7xl">
                    <span style={{ color: "green" }}>Y</span>ou{" "}
                    <span style={{ color: "green" }}>O</span>nly{" "}
                    <span style={{ color: "green" }}>D</span>igitalize{" "}
                    <span style={{ color: "green" }}>O</span>nce.
                  </h1>
                  <p className="mt-8  text-3xl text-gray-300">
                    So, Let’s digitalize your business like it is ours.
                  </p>
                </div>
              </div>
            </div>
            <div className="text-center mt-6">
              <button
                className="bg-gray-900 w-fit text-white active:bg-red-700 text-lg  px-6 py-3 font-bold  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 "
                type="button"
                onClick={() => { history.push('/contact') }}
                style={{
                  transition: "all .15s ease",
                  background: "green",
                  borderRadius: "32px",               
                }}
              >
                Don’t feel shy, Say Hi!
              </button>
            </div>
          </div>
        </div>

        <section className="pb-20 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-gray-800 w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl text-white font-semibold">
                      Performance Marketing
                    </h6>
                    <p className="mt-2 mb-4 text-gray-300">
                      Real results, not vanity metrics. YODO's data-driven
                      performance marketing gets you leads, sales, and ROI.
                    </p>
                    <a
                      className="underline underline-offset-2 text-gray-300"
                      href="#performancemarketing"
                      onClick={() => history.push("/Services")}
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-gray-800 w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl text-white font-semibold">Digital PR</h6>
                    <p className="mt-2 mb-4 text-gray-300">
                      Elevate your brand image and become a web celeb with our
                      strategic PR magic.{" "}
                    </p>
                    <a
                      className="underline underline-offset-2 text-gray-300"
                      href="#digital_pr"
                      onClick={() => history.push("/Services")}
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-gray-800 w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                      <i class="fa-brands fa-searchengin"></i>
                    </div>
                    <h6 className="text-xl text-white font-semibold">SEO</h6>
                    <p className="mt-2 mb-4 text-gray-300">
                      Unleash the organic traffic potential of your website and
                      watch leads roll in like a tidal wave of awesome.
                    </p>
                    <a
                      className="underline underline-offset-2 text-gray-300"
                      href="#seo"
                      onClick={() => history.push("/Services")}
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-gray-800 w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i class="fa-solid fa-envelope"></i>
                    </div>
                    <h6 className="text-xl text-white font-semibold">Email Marketing</h6>
                    <p className="mt-2 mb-4 text-gray-300">
                      Ditch boring blasts and craft email campaigns that win
                      hearts (and open rates) with YODO.
                    </p>
                    <a
                      className="underline underline-offset-2 text-gray-300"
                      href="#email_marketing"
                      onClick={() => history.push("/Services")}
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-gray-800 w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                      <i class="fa-solid fa-chart-simple"></i>
                    </div>
                    <h6 className="text-xl text-white font-semibold">
                      Influencer Marketing
                    </h6>
                    <p className="mt-2 mb-4 text-gray-300">
                      Partner with social media stars who resonate with your
                      target market and turn your brand into a meme-able
                      masterpiece.
                    </p>
                    <a
                      className="underline underline-offset-2 text-gray-300"
                      href="#influencer_marketing"
                      onClick={() => history.push("/Services")}
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-gray-800 w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                      <i class="fa-solid fa-code"></i>
                    </div>
                    <h6 className="text-xl text-white font-semibold">Web Development</h6>
                    <p className="mt-2 mb-4 text-gray-300">
                      Build a website that converts faster than a cheetah and
                      smoother than butter with YODO.
                    </p>
                    <a
                      className="underline underline-offset-2 text-gray-300"
                      href="#web_development"
                      onClick={() => history.push("/Services")}
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-7/12 px-4 mr-auto ml-auto">
                <div className="flex gap-8 items-center mb-6">
                  <div className="text-gray-300 p-3 text-center inline-flex items-center justify-center w-16 h-16  shadow-lg rounded-full text-yellow-650">
                    <i className="fas fa-user-friends text-xl" style={{ color: "#ffffff" }}></i>
                  </div>
                  <h3 className="text-2xl md:text-3xl lg:text-4xl  font-bold leading-normal text-center text-white">
                    Our Streamlined Approach
                  </h3>
                </div>
                <ol className="list-decimal pl-5 text-gray-300 space-y-4  pt-4">
                  <li className="pb-4 font-bold text-lg md:text-xl">
                    Discovery & Strategy
                    <ul className="pl-8 text-gray-300 py-2 space-y-1.5 font-medium text-base md:text-lg list-disc">
                      <li>
                        Deep dive into your business, target audience, and
                        goals.
                      </li>
                      <li>
                        Analyse your marketing landscape and identify
                        improvement areas.
                      </li>
                      <li>Craft a data-driven strategy with clear KPIs.</li>
                    </ul>
                  </li>
                  <li className="pb-4 font-bold text-lg md:text-xl">
                    Creative Execution
                    <ul className="pl-8 text-gray-300 py-2 space-y-1.5 font-medium text-base md:text-lg list-disc">
                      <li>Combine data insights with creative storytelling.</li>
                      <li>
                        Orchestrate campaigns across effective digital channels.
                      </li>
                    </ul>
                  </li>
                  <li className="pb-4 font-bold text-lg md:text-xl">
                    Continuous Improvement
                    <ul className="pl-8 text-gray-300 py-2 space-y-1.5 font-medium text-base md:text-lg list-disc">
                      <li>
                        Monitor performance and optimize for maximum impact and
                        budget.
                      </li>
                      <li>
                        Provide transparent communication with regular progress
                        reports.
                      </li>
                    </ul>
                  </li>
                </ol>

                <p className="text-xl font-semibold leading-relaxed mt-6 mb-4 text-gray-300 text-left">
                  This results-oriented approach ensures your marketing efforts
                  achieve real business growth.
                </p>

                <button
                  className="bg-gray-900 text-base text-white mt-4 active:bg-red-700  font-bold px-4 py-2.5  rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-8 "
                  type="button"
                  style={{
                    transition: "all .15s ease",
                    background: "green",
                  }}
                  onClick={() => history.push("/Contact")}
                >
                  Let's get started!
                </button>
              </div>

              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-gray-800 w-full mb-6 shadow-lg rounded-lg">
                  <img
                    alt="..."
                    src={Checklist}
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <p className="text-lg font-medium leading-relaxed mt-4 mb-4 text-gray-300">
                      At YODO, we don't just throw spaghetti at the digital
                      marketing wall and hope something sticks. We believe in a
                      structured, data-driven process that takes you from
                      initial goals to measurable success.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-20">
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-gray-800 w-full mb-6 shadow-lg rounded-lg">
                  <img
                    alt="..."
                    src={chooseus}
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <p className="text-lg font-medium leading-relaxed mt-4 mb-4 text-gray-300">
                      At YODO, we believe in a results-oriented approach that
                      blends global knowledge with cutting-edge data analysis and
                      a touch of strategic creativity. We're not interested in
                      just vanity metrics or empty clicks; we're all about driving
                      real business growth.
                    </p>
                  </blockquote>
                </div>
              </div>

              {/* <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src={chooseus}
                />
              </div> */}
              <div className="w-full md:w-5/12 ml-auto pt-6 lg:py-0 mr-auto px-4">
                <div className="md:pr-12">
                  <div className="flex mb-5 gap-8 items-center mb-6">
                    <div className="text-yellow-650 p-3 text-center inline-flex items-center justify-center w-16 h-16 shadow-lg rounded-full">
                      <i className="fas fa-rocket text-xl"></i>
                    </div>
                    <h3 className="text-3xl text-white font-semibold">Why Choose Us</h3>
                  </div>
                  {/* <p className="mt-4 text-lg leading-relaxed font-medium text-gray-300">
                    At YODO, we believe in a results-oriented approach that
                    blends global knowledge with cutting-edge data analysis and
                    a touch of strategic creativity. We're not interested in
                    just vanity metrics or empty clicks; we're all about driving
                    real business growth.
                  </p> */}
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-start">
                        <div>
                          <span className="text-base font-semibold inline-block py-1 px-2.5 uppercase rounded-full text-yellow-650  mr-3">
                            <i class="fa-solid fa-book"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-300 text-base md:text-lg font-bold pb-2">
                            Global Knowledge
                          </h4>
                          <p className="indent-8 font-medium text-sm md:text-base text-gray-300">
                            We leverage our extensive understanding of
                            international markets and consumer behaviour to
                            craft campaigns that resonate with audiences
                            worldwide.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-start">
                        <div>
                          <span className="text-base font-semibold inline-block py-1 px-2.5 uppercase rounded-full text-yellow-650  mr-3">
                            <i class="fa-solid fa-database"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-300 text-base md:text-lg font-bold pb-2">
                            Data-Driven Decisions
                          </h4>
                          <p className="indent-8 font-medium text-sm md:text-base text-gray-300">
                            We don't guess, we analyse. We use data to
                            understand your target audience, track campaign
                            performance and constantly optimize for maximum
                            impact.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-start">
                        <div>
                          <span className="text-base font-semibold inline-block py-1 px-2.5 uppercase rounded-full text-yellow-650  mr-3">
                            <i class="fa-solid fa-lightbulb"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-300 text-base md:text-lg font-bold pb-2">
                            Creative
                          </h4>
                          <p className="indent-8 font-medium text-sm md:text-base text-gray-300">
                            Data is powerful, but it needs a human touch. Our
                            team combines global knowledge with strategic
                            creativity to craft engaging campaigns that resonate
                            with your audience, regardless of location.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-start">
                        <div>
                          <span className="text-base font-semibold inline-block py-1 px-2.5 uppercase rounded-full text-yellow-650  mr-3">
                            <i class="fa-solid fa-face-laugh"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-300 text-base md:text-lg font-bold pb-2">
                            Fun (But Not Frivolous)
                          </h4>
                          <p className="indent-8 font-medium text-sm md:text-base text-gray-300">
                            We believe marketing should be enjoyable, not just
                            for you, but for your audience too. We inject a
                            touch of fun into our campaigns, making them more
                            engaging and memorable across cultural contexts.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <button
                  className="bg-gray-900 text-base text-white mt-4 active:bg-red-700  font-bold px-4 py-2.5  rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-8 "
                  type="button"
                  style={{
                    transition: "all .15s ease",
                    background: "green",
                  }}
                  onClick={() => history.push("/Contact")}
                >
                  Choose Us!
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="py-16">
          {" "}
          <div className="container mx-auto px-4">
            <h2 className="text-3xl text-white font-bold text-center mb-8">
              Companies that Trust Us
            </h2>
            <div className="text-center mb-8 animate-fade-in">
              <p className="text-xl text-gray-300 font-semibold">Number of Clients</p>
              <p className="text-5xl text-gray-300 font-bold">{clientCount}+</p>
            </div>
            <div className="flex gap-4 overflow-hidden">
              {logos.map((logo, index) => (
                <motion.div
                  key={logo.id}
                  className="flex flex-col items-center"
                  initial={{ x: "100%" }}
                  animate={{ x: "-100%" }}
                  transition={{
                    x: { duration: 5, repeat: Infinity, ease: "linear" },
                    delay: index * 2, // Adjust delay based on your needs
                  }}
                >
                  <>
                    <img
                      src={logo.image}
                      alt={logo.name}
                      className="w-96 h-56 object-contain mb-2 rounded-lg shadow-lg"
                    />
                    <p className="text-center  text-gray-300 text-xl font-semibold mt-2">
                      {logo.name}
                    </p>
                  </>
                </motion.div>
              ))}
            </div>
            {/* Client Shout Outs Slider */}
            <div className="mt-12">
              <h3 className="text-2xl font-bold mb-4 text-white text-center">
                Client Shout Outs
              </h3>
              <Slider {...sliderSettings} className="px-4">
                {clientShoutOuts.map((shoutOut) => (
                  <div key={shoutOut.id} className="px-4">
                    <div className="flex flex-col items-center justify-center bg-gray-800 p-6 rounded-lg shadow-md transform hover:scale-105 transition duration-300">
                      <p className="text-center text-white font-bold text-lg lg:text-xl mb-4">
                        {shoutOut.title}
                      </p>
                      <img
                        src={shoutOut.image}
                        alt={shoutOut.title}
                        className="w-40 h-40 rounded-full mb-4 shadow-lg"
                      />
                      <p className="text-center text-gray-300 font-semibold mb-2">
                        {shoutOut.name}
                      </p>
                      <p className="text-center text-gray-300 text-sm mb-2">
                        {shoutOut.description}
                      </p>
                      <p className="text-center text-gray-300 text-xs">
                        {shoutOut.date}
                      </p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <ToastContainer position="bottom-right" autoClose={5000} />
        </section>
      </main>
      <Footer />
    </section>
  );
}
