import React from 'react';

function ExpertCard({ image, name, title, description }) {
    return (
        <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden text-white">
            <div className="p-6">
                <div className="flex justify-center mb-4">
                    <img src={image} alt={name} className="rounded-full w-40 h-40 object-cover border-4 border-white" />
                </div>
                <h3 className="text-2xl font-semibold mb-2 text-center">{name}</h3>
                <p className="text-lg font-medium mb-2 text-center">{title}</p>
                <p className="text-base sm:text-lg text-center">{description}</p>
            </div>
        </div>
    );
}

export default ExpertCard;
