import React, {useState} from "react";
import { ToastContainer, toast } from "react-toastify";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export default function Footer() {
  const logo = process.env.PUBLIC_URL + "/Logo.png";
  const logoTitle = process.env.PUBLIC_URL + "/LogoTitle.png";
  const history = useHistory();
  const [email, setEmail] = useState();
  const [errors, setErrors] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);
  const validateForm = () => {
    let newErrors = {};
    if (!email) newErrors.email = "Email is required";
    return newErrors;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setIsSubmitting(true);

      const formDataToSubmit = new FormData();

      formDataToSubmit.append("email", email);

      try {
        const response = await fetch(
          "https://yododigital.com/yo_do_admin/public/api/email-send",
          {
            method: "POST",
            body: formDataToSubmit,
          }
        );

        const result = await response.json();
        if (response.ok && result.success) {
          toast.success(result.message || "Data submitted successfully.");
          
          setEmail('')
          
        } else {
          toast.error(result.message || "Error submitting contact form.");
        }
      } catch (error) {
        toast.error("Network error. Please try again later.");
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setErrors(formErrors);
    }
  };
  return (
    <footer className="relative bg-black pt-8 pb-6 text-white">
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style={{ height: "80px" }}
      >
        {/* <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-gray-900 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg> */}
      </div>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <>
              <img
                src={logo}
                className={"inline-block sm:mr-4 py-2 whitespace-nowrap"}
                style={{ height: "80px", width: "70px" }}
                alt="logo"
              ></img>
              <img
                src={logoTitle}
                className={"inline-block sm:mr-4 py-2 whitespace-nowrap"}
                style={{ height: "60px" }}
                alt="logoTitle"
              ></img>
            </>
            <div className="my-6 flex flex-col">
              <span className="block uppercase text-white text-sm font-semibold mb-4 indent-2">
                Follow us on
              </span>
              <div className="flex">
                <a href="https://www.instagram.com/yodo_digital?igsh=MXQ4M2F5dmVkMDJ1cA==" target="blank">
                  <button
                    className="flex bg-white text-blue-400 shadow-lg font-normal h-10 w-10 px-3 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i
                      class="fa-brands fa-instagram"
                      style={{ color: "#dc1818" }}

                    ></i>
                  </button>
                </a>
                <a href="https://www.facebook.com/share/oMP7V1JM92wNMPaq/?mibextid=qi2Omg" target="blank">

                  <button
                    className="flex bg-white text-blue-600 shadow-lg font-normal h-10 w-10 px-3 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="flex fab fa-facebook-square"></i>
                  </button>
                </a>
                <a href="https://www.linkedin.com/company/yodo-digital/" target="blank">

                  <button
                    className="flex bg-white text-pink-400 shadow-lg font-normal h-10 w-10 px-3 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i
                      class="fa-brands fa-linkedin-in"
                      style={{ color: "#163bf3" }}
                    ></i>
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex items-top flex-wrap mb-6">
              <div className="w-full lg:w-6/12 xl:w-4/12 lg:px-1 ml-auto  mb-3 lg:mb-0">
                <span className="block uppercase text-white text-sm font-semibold mb-4">
                  Reach Us
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-white hover:text-white font-semibold block pb-2 text-sm flex gap-2 items-center hover:underline"
                      href="tel:+919016885328"
                    >
                      <i class="fa-sharp fa-solid fa-phone"></i>
                      +91 9016885328
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-white hover:text-white font-semibold block pb-2 text-sm flex gap-2 items-center hover:underline"
                      href="mailto:contact@yododigital.com"
                    >
                      <i class="fa-regular fa-envelope"></i>
                      contact@yododigital.com
                    </a>
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-3/12 xl:w-4/12 lg:px-1 ml-auto mb-3 lg:mb-0">
                <span className="block uppercase text-white text-sm font-semibold mb-4">
                  Services
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-white hover:text-white font-semibold block pb-2 text-sm hover:underline"
                      href="#performancemarketing"
                      onClick={() => history.push("/Services")}
                    >
                      Performance Marketing
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-white hover:text-white font-semibold block pb-2 text-sm hover:underline"
                      href="#digital_pr"
                      onClick={() => history.push("/Services")}
                    >
                      Digital PR
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-white hover:text-white font-semibold block pb-2 text-sm hover:underline"
                      href="#seo"
                      onClick={() => history.push("/Services")}
                    >
                      SEO
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-white hover:text-white font-semibold block pb-2 text-sm hover:underline"
                      href="#email_marketing"
                      onClick={() => history.push("/Services")}
                    >
                      Email Marketing
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-white hover:text-white font-semibold block pb-2 text-sm hover:underline"
                      href="#influencer_marketing"
                      onClick={() => history.push("/Services")}
                    >
                      Influencer Marketing
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-white hover:text-white font-semibold block pb-2 text-sm hover:underline"
                      href="#web_development"
                      onClick={() => history.push("/Services")}
                    >
                      Web Development
                    </a>
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-3/12 xl:w-4/12 lg:px-1 ml-auto mb-3 lg:mb-0">
                <span className="block uppercase text-white text-sm font-semibold mb-2">
                  Quick Links
                </span>
                <ul className="list-unstyled">
                  <li>
                    <button
                      className="text-white hover:text-white font-semibold block pb-2 text-sm  hover:underline focus:outline-none"
                      onClick={() => history.push("/Contact")}
                    >
                      Contact Us
                    </button>
                  </li>
                  <li>
                    <button
                      className="text-white hover:text-white font-semibold block pb-2 text-sm hover:underline focus:outline-none"
                      onClick={() => history.push("/Resources")}
                    >
                      Resources
                    </button>
                  </li>
                  <li>
                    <button
                      className="text-white hover:text-white font-semibold block pb-2 text-sm hover:underline focus:outline-none"
                      onClick={() => history.push("/AboutUs")}
                    >
                      About Us
                    </button>
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-4/12 lg:px-1 mb-3 lg:mb-0">
                <span className="block uppercase text-white text-sm font-semibold mb-2">
                  Talk to Us Today
                </span>
                <ul className="list-unstyled">
                  <li>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-white text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="border-0 px-2 py-2 placeholder-gray-600 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring"
                        placeholder="Email"
                        style={{ transition: "all .15s ease" }}
                        disabled={isSubmitting}
                        onChange={(e)=>setEmail(e.target.value)}
                        value={email}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="mt-2">
                      <button
                        className="bg-gray-700 text-white active:bg-gray-500 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                        onClick={handleSubmit}
                      >
                        Send Message
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-400" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-xs text-white font-semibold py-1">
              Copyright © {new Date().getFullYear()} Yodo Digital{" "}
              <a
                href="https://www.creative-tim.com"
                className="text-white hover:text-white"
              >
                Creative Tim
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
