import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";

import Landing from "views/Landing.js";
import AboutUs from "views/AboutUs";
import Service from "views/Service";
import Resources from "views/Resources";
import Contact from "views/Contact";
import ResourceDetails from "views/ResourceDetails";


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path='/landing' component={Landing} />
      <Route path='/AboutUs' component={AboutUs} />
      <Route path='/Services' component={Service} />
      <Route exact  path='/Resources' component={Resources} />
      <Route path='/Contact' component={Contact} />
      <Route exact  path="/Resources/:id" component={ResourceDetails}/>
      <Redirect from='/' to='/landing' />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
