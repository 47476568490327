import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
const Resources = () => {
  const [blogs, setBlogs] = useState([]);
  const [visibleBlogs, setVisibleBlogs] = useState(3);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  useEffect(() => {
    fetch("https://yododigital.com/yo_do_admin/public/api/blog")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setBlogs(data.data);
        } else {
          toast.error("Failed to fetch blogs");
        }
      })
      .catch((error) => {
        toast.error("An error occurred while fetching blogs");
      });
  }, []);

  const loadMore = () => {
    setVisibleBlogs((prevVisible) => prevVisible + 3);
  };
  const handleClick = () => {
    history.push("/Contact");
    window.scrollTo(0, 0); // Scroll to the top
  };
  return (
    <section className="bg-[#11151c] text-white">
      <Navbar transparent />
      <main className="bg-[#11151c] text-white min-h-screen p-6 !pt-32 lg:!pt-30">
        <ToastContainer />
        <header className="text-center mb-12">
          <h1 className="text-4xl font-bold">Resources</h1>
        </header>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {blogs.slice(0, visibleBlogs).map((blog) => (
            <Link to={`/Resources/${blog.id}`} key={blog.id} className="block">
              <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                <img
                  src={blog.image}
                  alt={blog.title}
                  className="w-full h-48 object-cover mb-4 rounded-lg"
                />
                <h2 className="text-2xl font-bold mb-2">{blog.title}</h2>
                <p className="text-gray-400 mb-2">{blog.date}</p>
              </div>
            </Link>
          ))}
        </div>
        {visibleBlogs < blogs.length && (
          <div className="text-center mt-6">
            <button
              onClick={loadMore}
              className="bg-green-600 text-white px-4 py-2 rounded-lg"
            >
              Load More
            </button>
          </div>
        )}
        <section className="mt-12 bg-gray-900 p-6 rounded-lg lg:flex lg:justify-between lg:gap-8  items-center shadow-lg">
          <div>
            <h2 className="text-3xl font-bold mb-4">Have a project for us?</h2>
            <p className="text-lg mb-4">
              Let’s discuss how we can increase your customers using our
              expertise and grow your business. Let’s hop on a call to get
              started.
            </p>
          </div>
          {/* <Link to={'/Contact'} > */}
          <button
            className="bg-green-600 text-white min-h-10 min-w-56 lg:min-h-11 lg:min-w-64  rounded-lg"
            onClick={handleClick}
          >
            Ready for more customers?
          </button>
          {/* </Link> */}
        </section>
      </main>
      <Footer />
    </section>
  );
};

export default Resources;
