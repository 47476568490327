import React, { useEffect, useState } from "react";
import Navbar from "components/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "components/Footer";

export default function Contact() {
  const contactus = process.env.PUBLIC_URL + "/Contact_Us_Intro.jpeg";

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    website: "",
    services: [],
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevState) => {
      const newServices = checked
        ? [...prevState.services, value]
        : prevState.services.filter((service) => service !== value);
      return { ...prevState, services: newServices };
    });
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First Name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.phone) { newErrors.phone = "Phone is required"; }
    else if (formData.phone.length > 10 || formData.phone.length < 10) {
      newErrors.phone = "Enter valid Phone No.";
    }
    // if (!formData.company) newErrors.company = "Company is required";
    // if (!formData.website) newErrors.website = "Website is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setIsSubmitting(true);

      const formDataToSubmit = new FormData();
      formDataToSubmit.append("first_name", formData.firstName);
      formDataToSubmit.append("last_name", formData.lastName);
      formDataToSubmit.append("email", formData.email);
      formDataToSubmit.append("phone_number", formData.phone);
      formDataToSubmit.append("company", formData.company);
      formDataToSubmit.append("website", formData.website);
      formDataToSubmit.append("description", formData.message);
      formDataToSubmit.append("service", formData.services.join(", "));

      try {
        const response = await fetch(
          "https://yododigital.com/yo_do_admin/public/api/conatct-us",
          {
            method: "POST",
            body: formDataToSubmit,
          }
        );

        const result = await response.json();
        if (response.ok && result.success) {
          toast.success(result.message || "Data submitted successfully.");
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            company: "",
            website: "",
            services: [],
            message: "",
          });
        } else {
          toast.error(result.message || "Error submitting contact form.");
        }
      } catch (error) {
        toast.error("Network error. Please try again later.");
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setErrors(formErrors);
    }
  };
  return (
    <section className="bg-[#11151c]">
      <Navbar transparent />
      <main>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div
          className="relative pt-32 md:pt-16 pb-32 flex content-center items-center justify-center"
          style={{
            minHeight: "40vh",
          }}
        >
          <div className="absolute top-0 w-full h-full">
            <img
              src={contactus}
              className="absolute top-0 w-full h-full object-cover"
            ></img>
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-25 bg-black"
            ></span>
          </div>

          <div className="container relative mx-auto mt-28">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-full px-4 ml-auto mr-auto text-center">
                <div className="text-center">
                  <h1 className="text-white font-semibold text-5xl">
                    Ready for More Customers? Let’s get to it!
                  </h1>

                </div>
              </div>
            </div>

          </div>
        </div>

        {/* <p className="mt-8  text-3xl text-gray-300 ">
          Reach Via
        </p> */}
        <div className="text-center  flex flex-col">
            <h2 className="mt-8  text-3xl text-gray-300">  Reach Via</h2>
          </div>
        <section className="relative lg:pt-0 bg-[#11151c]">
          <div className="container mx-auto px-4 !pt-12 lg:pt-30">
            <div className="text-center text-white mb-12">
              {/* <div className="p-4 space-y-3">
                <h2 className="font-bold text-3xl">
                  Ready for More Customers? Let’s get to it!
                </h2>
                <p className="font-medium text-xl text-gray-300">Reach Via</p>
              </div> */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3  bg-[#11151c] text-white">
                <div className="border-b-2 md:border-b-0 md:border-r-2 border-gray-500 p-4">
                  <p className="font-bold text-xl">Email:</p>
                  <a
                    href="mailto:contact@yododigital.com"
                    className="text-gray-400 text-lg hover:underline"
                  >
                    contact@yododigital.com
                  </a>
                </div>


                <div className="border-b-2 md:border-b-0 lg:border-r-2 border-gray-500 p-4">
                  <p className="font-bold text-xl">Mobile No.:</p>
                  <a
                    href="tel:+919016885328"
                    className="text-gray-400 text-lg hover:underline"
                  >
                    +91 9016885328
                  </a>
                </div>
                <div className=" border-gray-500 p-4">
                  <p className="font-bold text-xl">Careers:</p>
                  <a
                    href="mailto:careers@yododigital.com"
                    className="text-gray-400 text-lg hover:underline"
                  >
                    careers@yododigital.com
                  </a>
                </div>
              </div>

              <div className="pt-8 pb-4">
                <p className="font-bold text-2xl p-2">Location:</p>
                <p className="text-gray-400 text-xl">
                  Our reach is global. Our home is Pune, India.
                </p>
              </div>
            </div>
            <div className="flex justify-center mb-24">
              <div className="w-full lg:w-8/12 px-4">
                <div className="relative bg-gray-300 p-6 sm:p-10 rounded-lg shadow-lg">
                  <h4 className="text-2xl font-semibold text-gray-800 mb-4">
                    Ready for More Customers? Let’s get to it!
                  </h4>
                  <p className="text-gray-600 mb-8">
                    Complete this form and we will get back to you within 24
                    hours.
                  </p>
                  <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                      <div className="relative">
                        <label className="block text-gray-700 text-xs font-bold mb-2">
                          First Name *
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          className="border-0 block w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded-sm text-sm shadow focus:outline-none focus:ring"
                          placeholder="First Name"
                          value={formData.firstName}
                          onChange={handleChange}
                          disabled={isSubmitting}
                        />
                        {errors.firstName && (
                          <p className="text-red-500 text-xs mt-1">
                            {errors.firstName}
                          </p>
                        )}
                      </div>
                      <div className="relative">
                        <label className="block text-gray-700 text-xs font-bold mb-2">
                          Last Name *
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          className="border-0 block w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded-sm text-sm shadow focus:outline-none focus:ring"
                          placeholder="Last Name"
                          value={formData.lastName}
                          onChange={handleChange}
                          disabled={isSubmitting}
                        />
                        {errors.lastName && (
                          <p className="text-red-500 text-xs mt-1">
                            {errors.lastName}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6">
                      <div className="relative">
                        <label className="block text-gray-700 text-xs font-bold mb-2">
                          Email *
                        </label>
                        <input
                          type="email"
                          name="email"
                          className="border-0 block w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded-sm text-sm shadow focus:outline-none focus:ring"
                          placeholder="Email"
                          value={formData.email}
                          onChange={handleChange}
                          disabled={isSubmitting}
                        />
                        {errors.email && (
                          <p className="text-red-500 text-xs mt-1">
                            {errors.email}
                          </p>
                        )}
                      </div>
                      <div className="relative">
                        <label className="block text-gray-700 text-xs font-bold mb-2">
                          Phone *
                        </label>
                        <input
                          type="text"
                          name="phone"
                          className="border-0 block w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded-sm text-sm shadow focus:outline-none focus:ring"
                          placeholder="Phone"
                          value={formData.phone}
                          onChange={handleChange}
                          disabled={isSubmitting}
                        />
                        {errors.phone && (
                          <p className="text-red-500 text-xs mt-1">
                            {errors.phone}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6">
                      <div className="relative">
                        <label className="block text-gray-700 text-xs font-bold mb-2">
                          Company
                        </label>
                        <input
                          type="text"
                          name="company"
                          className="border-0 block w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded-sm text-sm shadow focus:outline-none focus:ring"
                          placeholder="Company"
                          value={formData.company}
                          onChange={handleChange}
                          disabled={isSubmitting}
                        />
                        {errors.company && (
                          <p className="text-red-500 text-xs mt-1">
                            {errors.company}
                          </p>
                        )}
                      </div>
                      <div className="relative">
                        <label className="block text-gray-700 text-xs font-bold mb-2">
                          Website
                        </label>
                        <input
                          type="text"
                          name="website"
                          className="border-0 block w-full px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded-sm text-sm shadow focus:outline-none focus:ring"
                          placeholder="Website"
                          value={formData.website}
                          onChange={handleChange}
                          disabled={isSubmitting}
                        />
                        {errors.website && (
                          <p className="text-red-500 text-xs mt-1">
                            {errors.website}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-6">
                      <label className="block text-gray-700 text-xs font-bold mb-2">
                        Services Interested In
                      </label>
                      <div className="flex flex-wrap -mx-1">
                        <div className="w-1/2 px-1 mb-2">
                          <label className="inline-flex items-center">
                            <input
                              type="checkbox"
                              className="form-checkbox h-5 w-5 text-gray-600"
                              value="Performance_Marketing"
                              onChange={handleCheckboxChange}
                              disabled={isSubmitting}
                            />
                            <span className="ml-2 text-gray-700">
                              Performance Marketing
                            </span>
                          </label>
                        </div>
                        <div className="w-1/2 px-1 mb-2">
                          <label className="inline-flex items-center">
                            <input
                              type="checkbox"
                              className="form-checkbox h-5 w-5 text-gray-600"
                              value="Digital_PR"
                              onChange={handleCheckboxChange}
                              disabled={isSubmitting}
                            />
                            <span className="ml-2 text-gray-700">
                              Digital PR
                            </span>
                          </label>
                        </div>
                        <div className="w-1/2 px-1 mb-2">
                          <label className="inline-flex items-center">
                            <input
                              type="checkbox"
                              className="form-checkbox h-5 w-5 text-gray-600"
                              value="SEO"
                              onChange={handleCheckboxChange}
                              disabled={isSubmitting}
                            />
                            <span className="ml-2 text-gray-700">SEO</span>
                          </label>
                        </div>
                        <div className="w-1/2 px-1 mb-2">
                          <label className="inline-flex items-center">
                            <input
                              type="checkbox"
                              className="form-checkbox h-5 w-5 text-gray-600"
                              value="Email_Marketing"
                              onChange={handleCheckboxChange}
                              disabled={isSubmitting}
                            />
                            <span className="ml-2 text-gray-700">
                              Email Marketing
                            </span>
                          </label>
                        </div>
                        <div className="w-1/2 px-1 mb-2">
                          <label className="inline-flex items-center">
                            <input
                              type="checkbox"
                              className="form-checkbox h-5 w-5 text-gray-600"
                              value="Influencer_Marketing"
                              onChange={handleCheckboxChange}
                              disabled={isSubmitting}
                            />
                            <span className="ml-2 text-gray-700">
                              Influencer Marketing
                            </span>
                          </label>
                        </div>
                        <div className="w-1/2 px-1 mb-2">
                          <label className="inline-flex items-center">
                            <input
                              type="checkbox"
                              className="form-checkbox h-5 w-5 text-gray-600"
                              value="Web_Development"
                              onChange={handleCheckboxChange}
                              disabled={isSubmitting}
                            />
                            <span className="ml-2 text-gray-700">
                              Web Development
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6">
                      <label className="block text-gray-700 text-xs font-bold mb-2">
                        Message
                      </label>
                      <textarea
                        name="message"
                        className="border-0 block w-full px-3 py-3 mb-4 placeholder-gray-400 text-gray-700 bg-white rounded-sm text-sm shadow focus:outline-none focus:ring"
                        rows="4"
                        placeholder="Enter your message here..."
                        value={formData.message}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      ></textarea>
                    </div>
                    <button
                      type="submit"
                      className={`bg-gray-800 text-white active:bg-gray-700  text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ${isSubmitting ? "cursor-not-allowed opacity-50" : ""
                        }`}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </section>
  );
}
