import React, { useEffect } from "react";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Service() {
  const performance = process.env.PUBLIC_URL + "/Analysis-bro.png";
  const digitalPr = process.env.PUBLIC_URL + "/digital-pr.png";
  const seo = process.env.PUBLIC_URL + "/SEO.png";
  const email = process.env.PUBLIC_URL + "/email.png";
  const Influencer = process.env.PUBLIC_URL + "/Influencer.png";
  const web = process.env.PUBLIC_URL + "/web.png";
  const history = useHistory();
  const video = process.env.PUBLIC_URL + "/Service_Page_Intro.mp4";

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  
  const handleClick = () => {
    history.push("/Contact");
    window.scrollTo(0, 0); // Scroll to the top
  };
  return (
    <section className="bg-[#11151c]">
      <Navbar transparent />
      <main >
        {/* <header className="flex flex-col text-white text-center py-8 px-8 bg-[#11151c]"> */}
        <div
          className="relative pt-32 md:pt-16 pb-32 flex content-center items-center justify-center"
          style={{
            minHeight: "40vh",
          }}
        >
          <div className="absolute top-0 w-full h-full">
            <video
              autoPlay
              muted
              loop
              className="absolute top-0 w-full h-full object-cover"
              onError={(e) => console.error("Video playback error:", e)}
            >
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-25 bg-black"
            ></span>
          </div>

          <div className="container relative mx-auto mt-28">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-full px-4 ml-auto mr-auto text-center">
                <div className="text-center flex flex-col items-center">
                  <h1 className="text-white font-semibold text-5xl">
                  Unleash Strategic Growth with YODO’s Customized Service Menu
                  </h1>
                  <p className="mt-8 w-3/4  text-3xl text-gray-300">
                  Ready to ditch the generic and embrace a data-driven approach to
            digital marketing? Here at YODO, we offer a comprehensive suite of
            services designed to propel your brand to new heights.
                  </p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
          {/* <h1 className="text-5xl lg:text-5xl font-bold md:w-3/4 lg:w-1/2 mb-4 self-center">
            Unleash Strategic Growth with YODO’s Customized Service Menu
          </h1>
          <p className="mt-4 text-lg  md:w-3/4 self-center">
            Ready to ditch the generic and embrace a data-driven approach to
            digital marketing? Here at YODO, we offer a comprehensive suite of
            services designed to propel your brand to new heights.
          </p> */}
        {/* </header> */}

        <section className="container text-gray-100 bg-[#11151c] mx-auto py-12 px-4 mb-12">
          <div className="text-center mb-12 flex flex-col">
            <h2 className="text-4xl font-semibold">Our Services</h2>
            <p className="mt-4 text-lg px-4 md:w-3/4 self-center">
              We understand that every business is unique, with distinct goals
              and target audiences. That's why we don't believe in a
              one-size-fits-all solution. Our team of experts will work closely
              with you to craft a customized strategy encompassing the most
              impactful digital channels, whether it's SEO that skyrockets your
              rankings, social media campaigns that ignite audience engagement,
              or targeted PPC efforts that drive qualified leads.
            </p>
          </div>
          <div className="grid gap-12 lg:grid-cols-1">
            {/* Performance Marketing */}
            <div
              className="bg-gray-800 p-6 rounded-lg shadow-md flex flex-col md:flex-row items-center"
              id="performancemarketing"
            >
              <img
                src={performance}
                alt="Performance Marketing"
                className="w-full h-64 md:w-96 md:h-96 object-cover rounded-lg mb-4 md:mb-0 md:mr-6"
              />
              <div>
                <h3 className="text-3xl font-bold">Performance Marketing</h3>
                <p className="mt-4 text-red-400 font-semibold">Problem:</p>
                <p>
                  Struggling to see a return on your ad spend? Ads just not
                  converting?
                </p>
                <p className="mt-4 text-green-400 font-semibold">Solution:</p>
                <p>
                  We're data wizards who craft high-performing campaigns across
                  platforms like Google Ads, social media ads, and more. We
                  target the right audience, optimize relentlessly, and ensure
                  your budget delivers measurable results.
                </p>
                <p className="mt-4">
                  Here's what You Can Expect: Explosive growth in leads, sales,
                  or brand awareness – watch your ROI skyrocket!
                </p>
                <div className="flex justify-between  gap-2 mt-4 items-center">
                  <p>Turn your ad spend into a revenue machine</p>
                  <button
                    className="bg-green-600 text-white min-w-10 min-h-10 rounded-full hover:bg-green-700"
                    onClick={() => history.push("/Contact")}
                  >
                    <i class="fa-solid fa-arrow-right fa-lg"></i>
                  </button>
                </div>
              </div>
            </div>

            {/* Digital PR */}
            <div
              className="bg-gray-800 p-6 rounded-lg shadow-md flex flex-col md:flex-row items-center"
              id="digital_pr"
            >
              <img
                src={digitalPr}
                alt="Digital PR"
                className="w-full h-64 md:h-96 md:w-96 object-cover rounded-lg mb-4 md:mb-0 md:mr-6"
              />
              <div>
                <h3 className="text-3xl font-bold">Digital PR</h3>
                <p className="mt-4 text-red-400 font-semibold">Problem:</p>
                <p>
                  Feeling unheard in the digital jungle? Your brand deserves the
                  spotlight!
                </p>
                <p className="mt-4 text-green-400 font-semibold">Solution:</p>
                <p>
                  
                  We craft strategic PR campaigns that land you features in top
                  publications, build brand awareness, and establish you as an
                  industry thought leader. No bee suits required, just impactful
                  storytelling.
                </p>
                <p className="mt-4">
                  Here's what You Can Expect: A surge in website traffic,
                  improved brand reputation, and a chorus of positive mentions
                  online.
                </p>
                <div className="flex justify-between mt-4 items-center">
                  <p>Let's get your brand buzzing with the right audience</p>
                  <button
                    className="bg-green-600 text-white min-w-10 min-h-10 rounded-full hover:bg-green-700"
                    onClick={() => history.push("/Contact")}
                  >
                    <i class="fa-solid fa-arrow-right fa-lg"></i>
                  </button>
                </div>
              </div>
            </div>

            {/* SEO */}
            <div
              className="bg-gray-800 p-6 rounded-lg shadow-md flex flex-col md:flex-row items-center"
              id="seo"
            >
              <img
                src={seo}
                alt="SEO"
                className="w-full h-64 md:h-96 md:w-96 object-cover rounded-lg mb-4 md:mb-0 md:mr-6"
                
              />
              <div>
                <h3 className="text-3xl font-bold">SEO</h3>
                <p className="mt-4 text-red-400 font-semibold">Problem:</p>
                <p>
                  Stuck on page 17 of Google? Your website deserves to be seen!
                </p>
                <p className="mt-4 text-green-400 font-semibold">Solution:</p>
                <p>
                  Our SEO ninjas wield the power of keyword research, technical
                  optimization, and strategic content creation to help you
                  dominate search results. We'll get you climbing the Google
                  ladder with ease.
                </p>
                <p className="mt-4">
                  Here's what You Can Expect: More organic traffic, higher
                  leads, and increased brand visibility. Get ready to watch your
                  website rise to the top!
                </p>

                <div className="flex justify-between  gap-2 mt-4 items-center">
                  <p>Let's optimize your website for search engine success</p>
                  <button
                    className="bg-green-600 text-white min-w-10 min-h-10 rounded-full hover:bg-green-700"
                    onClick={() => history.push("/Contact")}
                  >
                    <i class="fa-solid fa-arrow-right fa-lg"></i>
                  </button>
                </div>
              </div>
            </div>
            

            {/* Email Marketing */}
            <div
              className="bg-gray-800 p-6 rounded-lg shadow-md flex flex-col md:flex-row items-center"
              id="email_marketing"
            >
              <img
                src={email}
                alt="Email Marketing"
                className="w-full h-64 md:h-96 md:w-96 object-cover rounded-lg mb-4 md:mb-0 md:mr-6"
              />
              <div>
                <h3 className="text-3xl font-bold">Email Marketing</h3>
                <p className="mt-4 text-red-400 font-semibold">Problem:</p>
                <p>
                  Is your email list a ghost town? Your emails deserve to be
                  opened and engaged with!
                </p>
                <p className="mt-4 text-green-400 font-semibold">Solution:</p>
                <p>
                  We design captivating email campaigns that nurture leads,
                  drive sales, and build relationships with your audience. We
                  ditch the spam and create emails people actually want to read.
                </p>
                <p className="mt-4">
                  Here's what You Can Expect: Increased engagement, boosted
                  conversions, and a loyal customer base built through email
                  nurture.
                </p>

                <div className="flex justify-between  gap-2 mt-4 items-center">
                  
                  <p>
                    Let's turn your email list into a powerful marketing tool
                  </p>
                  <button
                    className="bg-green-600 text-white min-w-10 min-h-10 rounded-full hover:bg-green-700"
                    onClick={() => history.push("/Contact")}
                  >
                    <i class="fa-solid fa-arrow-right fa-lg"></i>
                  </button>
                </div>
              </div>
            </div>

            {/* Influencer Marketing */}
            <div
              className="bg-gray-800 p-6 rounded-lg shadow-md flex flex-col md:flex-row items-center"
              id="influencer_marketing"
            >
              <img
                src={Influencer}
                alt="Influencer Marketing"
                className="w-full h-64 md:h-96 md:w-96 object-cover rounded-lg mb-4 md:mb-0 md:mr-6"
              />
              <div>
                <h3 className="text-3xl font-bold">Influencer Marketing</h3>
                <p className="mt-4 text-red-400 font-semibold">Problem:</p>
                <p>
                  Struggling to connect with your target audience? Influencer
                  partnerships can be a game-changer!
                </p>
                <p className="mt-4 text-green-400 font-semibold">Solution:</p>
                <p>
                  We identify the right influencers who resonate with your brand
                  
                  and craft strategic partnerships that amplify your message and
                  reach new audiences. No influencer drama, just authentic
                  connections.
                </p>
                <p className="mt-4">
                  Here's what You Can Expect: Increased brand awareness,
                  improved trust, and a surge in website traffic and sales.
                </p>

                <div className="flex justify-between  gap-2 mt-4 items-center">
                  <p>
                    {" "}
                    Let's connect you with the perfect influencers to elevate
                    your brand
                  </p>
                  <button
                    className="bg-green-600 text-white min-w-10 min-h-10 rounded-full hover:bg-green-700"
                    onClick={() => history.push("/Contact")}
                  >
                    <i class="fa-solid fa-arrow-right fa-lg"></i>
                  </button>
                </div>
              </div>
            </div>

            {/* Web Development */}
            <div
              className="bg-gray-800 p-6 rounded-lg shadow-md flex flex-col md:flex-row items-center"
              id="web_development"
            >
              <img
                src={web}
                alt="Web Development"
                className="w-full h-64 md:h-96 md:w-96 object-cover rounded-lg mb-4 md:mb-0 md:mr-6"
              />
              <div>
                <h3 className="text-3xl font-bold">Web Development</h3>
                <p className="mt-4 text-red-400 font-semibold">Problem:</p>
                <p>
                  Website outdated and dragging you down? Your online presence
                  should be a masterpiece!
                </p>
                <p className="mt-4 text-green-400 font-semibold">Solution:</p>
                <p>
                  We design and develop stunning websites that are
                  user-friendly, mobile-responsive, and convert visitors into
                  leads. We handle all the technical stuff – you just focus on
                  wowing your audience.
                </p>
                <p className="mt-4">
                  Here's what You Can Expect: A beautiful, functional and
                  responsive website that drives results. Get ready to
                  experience the joy of a website that works for you!
                </p>
                <div className="flex justify-between  gap-2 mt-4 items-center">
                  <p> Let's build a website that reflects your brand and fuels your
                  success</p>
                  <button
                    className="bg-green-600 text-white min-w-10 min-h-10 rounded-full hover:bg-green-700"
                    onClick={() => history.push("/Contact")}
                  >
                    <i class="fa-solid fa-arrow-right fa-lg"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="my-12 bg-gray-900 p-6  text-white w-11/12  mx-auto rounded-lg lg:flex lg:justify-between lg:gap-8  items-center shadow-lg">
          <div>
            <h2 className="text-3xl font-bold mb-4">Your one-stop shop for all things digital marketing.</h2>
            <p className="text-lg mb-4">
            Let's grow your business together!
            </p>
          </div>
          {/* <Link to={'/Contact'} > */}
          <button
            className="bg-green-600 text-white min-h-10 min-w-56 lg:min-h-11 lg:min-w-64  rounded-lg"
            onClick={handleClick}
          >
            Contact Us
          </button>
          {/* </Link> */}
        </section>
        <Footer />
      </main>
    </section>
  );
}
