import React, { useEffect } from "react";
import Navbar from "components/Navbar";
import ExpertCard from "components/ExpertCard";
import Footer from "components/Footer";

export default function AboutUs() {
  const priyanshi = process.env.PUBLIC_URL + "/priyanshi.jpg";
  const kushal = process.env.PUBLIC_URL + "/kushal.jpg";
  const ayush = process.env.PUBLIC_URL + "/ayush.jpg";
  const kevin = process.env.PUBLIC_URL + "/kevin.jpg";
  const rujesh = process.env.PUBLIC_URL + "/rujesh.jpg";
  const aboutus = process.env.PUBLIC_URL + "/About_Us_Page_Intro.jpg";

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  return (
    <section className="bg-[#11151c]">
      <Navbar transparent />
      <div
          className="relative pt-32 md:pt-16 pb-32 flex content-center items-center justify-center"
          style={{
            minHeight: "40vh",
          }}
        >
        <div className="absolute top-0 w-full h-full">
          <img
            src={aboutus}
            className="absolute top-0 w-full h-full object-cover opacity-40"
          ></img>
          <span
            id="blackOverlay"
            className="w-full h-full absolute opacity-25 bg-black"
          ></span>
        </div>

        <div className="container relative mx-auto mt-28">
          <div className="items-center flex flex-wrap">
            <div className="w-full lg:w-full px-4 ml-auto mr-auto text-center">
              <div className="text-center">
                <h1 className="text-white font-semibold text-5xl">
                  About Us
                </h1>
                <p className="mt-8  text-3xl text-white">
                  We believe in YODO (You Only Digitalize Once) – that's why we craft
                  watertight marketing strategies that not only grab attention, but
                  build lasting connections and, most importantly, drive results.
                  Imagine a team that blends creative firepower with strategic
                  mastery. That's YODO!
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
      <main className="bg-[#11151c] text-white p-6 sm:p-12 min-h-screen  mb-12">

        {/* <section className="text-center mb-12">
          <h1 className="text-4xl sm:text-5xl font-bold mb-4">About Us</h1>
          <p className="text-xl sm:text-2xl max-w-3xl mx-auto">
            We believe in YODO (You Only Digitalize Once) – that's why we craft
            watertight marketing strategies that not only grab attention, but
            build lasting connections and, most importantly, drive results.
            Imagine a team that blends creative firepower with strategic
            mastery. That's YODO!
          </p>
        </section> */}

        <div className="flex flex-col lg:flex-row gap-6 mb-6">
          <div className="lg:w-1/2 p-8  rounded-lg border border-white shadow-lg">
            <h2 className="text-3xl sm:text-4xl font-bold mb-4 text-center">Vision</h2>
            <p className="text-lg sm:text-xl text-center max-w-xl mx-auto">
              YODO envisions a world where every business, regardless of size or
              industry, thrives in the digital landscape. We see a future where
              strategic marketing empowers brands to connect with their
              audiences in a meaningful way, driving sustainable growth and
              achieving legendary online success.
            </p>
          </div>

          <div className="lg:w-1/2 p-8  rounded-lg border border-white shadow-lg">
            <h2 className="text-3xl sm:text-4xl font-bold mb-4 text-center">Mission</h2>
            <p className="text-lg sm:text-xl text-center max-w-xl mx-auto">
              YODO's mission is to be the premier digital marketing partner,
              crafting innovative and data-driven strategies that propel brands
              to the forefront of the online world. We are dedicated to
              exceeding client expectations, building lasting partnerships, and
              fostering a fun and collaborative environment where our team's
              expertise fuels your digital dominance.
            </p>
          </div>
        </div>

        <section className="mb-8">
          <div className="text-center  flex flex-col">
            <h2 className="text-4xl font-semibold">Our Experts</h2>
          </div>
          <h2 className="text-3xl sm:text-4xl font-bold mb-8 justify-center"></h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden text-white ">
              <div className="p-6">
                <div className="flex justify-center mb-4">
                  <img src={rujesh} alt={'name'} className="rounded-full w-48 h-48 object-cover border-4 border-white" />
                </div>
                <h3 className="text-2xl font-semibold mb-2 text-center">Rujesh Chovatiya</h3>
                <p className="text-lg font-medium mb-2 text-center">Founder / CEO</p>
                <p className="text-base sm:text-lg text-center">At YODO, our success is built upon a foundation of innovation and strategic, and global vision. Leading the charge is Rujesh Chovatiya, our Founder and CEO. He's not just the mastermind behind the agency; he's the architect, meticulously crafting a roadmap to digital dominance for each and every client.</p>
              </div>
            </div>

            <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden text-white ">
              <div className="p-6">
                <div className="flex justify-center mb-4">
                  <img src={kevin} alt={'name'} className="rounded-full w-48 h-48 object-cover border-4 border-white" />
                </div>
                <h3 className="text-2xl font-semibold mb-2 text-center">Kevin Donga</h3>
                <p className="text-lg font-medium mb-2 text-center">Co-Founder / CMO</p>
                <p className="text-base sm:text-lg text-center">When it comes to crafting compelling narratives, his background in marketing allows him to transform raw concepts into emotionally charged campaigns that resonate deeply with audiences. Imagine a visual storyteller weaving data-driven insights into captivating narratives – that's Kevin's superpower.</p>
              </div>
            </div>

            {/* <ExpertCard
              image="path/to/rujesh-image.jpg"
              name="Rujesh Chovatiya"
              title="Founder / CEO"
              description="At YODO, our success is built upon a foundation of innovation and strategic, and global vision. Leading the charge is Rujesh Chovatiya, our Founder and CEO. He's not just the mastermind behind the agency; he's the architect, meticulously crafting a roadmap to digital dominance for each and every client."
            /> */}
            {/* <ExpertCard
              image="path/to/kevin-image.jpg"
              name="Kevin Donga"
              title="Co-Founder / CMO"
              description="When it comes to crafting compelling narratives, his background in marketing allows him to transform raw concepts into emotionally charged campaigns that resonate deeply with audiences. Imagine a visual storyteller weaving data-driven insights into captivating narratives – that's Kevin's superpower."
            /> */}
          </div>

        </section>

        {/* <section> */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">

          <ExpertCard
            image={priyanshi}
            name="Priyanshi Jogani"
            title="Chief Creative Officer (CCO)"
            description="At YODO, we believe powerful storytelling is the heart of every successful digital marketing campaign. Leading our creative charge is Priyanshi Jogani, a visionary leader with a passion for crafting engaging experiences that resonate with audiences."
          />

          <ExpertCard
            image={ayush}
            name="Ayush Varsani"
            title="Chief Technology Officer (CTO)"
            description="Ayush's dedication to his craft goes beyond writing code. He's constantly on the cutting edge of web development trends, incorporating the latest technologies and best practices into his work. This ensures your website is not just functional today, but also future-proofed to adapt to the ever-evolving digital landscape."
          />

          <ExpertCard
            image={kushal}
            name="Kushal Gajera"
            title="SEO Director"
            description="Kushal Gajera is a digital marketing veteran with a proven track record of success in SEO. Throughout his 4-year career, he has helped businesses of all sizes achieve significant improvements in their organic search ranking and visibility. Kushal's specialties include keyword research, competitor analysis, technical SEO audits, and link building strategies."
          />
        </div>

        {/* </section> */}


      </main>
      <Footer />
    </section>
  );
}
