import React from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import logo from '../../public/Logo.png';
export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const logo = process.env.PUBLIC_URL + "/Logo.png";
  const logoTitle = process.env.PUBLIC_URL + "/LogoTitle.png";
  const history = useHistory();
  return (
    <nav
      className={
        (props.transparent
          ? "top-0 absolute z-50 w-full"
          : "relative bg-white shadow-lg") +
        " flex  items-center justify-between px-2 py-3 w-full "
      }
    >
      <div className="container flex items-center min-w-full justify-between px-8">
        <div className="min-w-full relative flex items-center justify-between ">
          <>
            <Link to="/landing">
              <img
                src={logo}
                className={
                  (props.transparent ? "text-white" : "text-gray-800") +
                  " text-sm font-bold leading-relaxed inline-block sm:mr-4 py-2 whitespace-nowrap uppercase"
                }
                style={{ height: "80px", width: "70px" }}
              ></img>
              {/* {navbarOpen == false && */}
              <img
                src={logoTitle}
                className={
                  (props.transparent ? "text-white" : "text-gray-800") +
                  " text-sm font-bold leading-relaxed inline-block sm:mr-4 py-2 whitespace-nowrap uppercase"
                }
                style={{ height: "60px" }}
              ></img>
            </Link>
          </>
          {/* <a
                className={
                  (props.transparent ? "text-white" : "text-gray-800") +
                  " text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
                  }
                  href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/presentation"
                  >
                  YODO Digital
                  </a> */}
          <span className=" hidden lg:inline-block">
            <Link to="/Services">
              <a
                className={
                  (props.transparent ? "text-white" : "text-gray-800") +
                  " text-base font-bold leading-relaxed inline-block  mr-4 py-2 whitespace-nowrap uppercase"
                }
              // href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/presentation"
              >
                Service
              </a>
            </Link>
            <Link to="/AboutUs">
              <a
                className={
                  (props.transparent ? "text-white" : "text-gray-800") +
                  " text-base font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
                }
              // href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/presentation"
              >
                About Us
              </a>
            </Link>
            <Link to="/Contact">
              <a
                className={
                  (props.transparent ? "text-white" : "text-gray-800") +
                  " text-base font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
                }
              // href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/presentation"
              >
                Contact Us
              </a>
            </Link>
            <Link to="/Resources">
              <a
                className={
                  (props.transparent ? "text-white" : "text-gray-800") +
                  " text-base font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
                }
              // href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/presentation"
              >
                Resources
              </a>
            </Link>
          </span>
          {/* } */}
          <button
            className="cursor-pointer text-xl leading-none sm:px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <i
              className={
                (props.transparent ? "text-white" : "text-gray-800") +
                " fas fa-bars"
              }
            ></i>
          </button>
          <div className="gap-8 hidden lg:flex items-center">
            {/* <a
              className="text-white hover:text-white font-semibold block pb-2 text-sm flex gap-2 items-center hover:underline"
              href="tel:+9016885328"
            >
              <i class="fa-sharp fa-solid fa-phone"></i>
              +91 9016885328
            </a> */}
            <a
              className="text-white hover:text-white font-semibold block  text-sm flex gap-2 items-center hover:underline"
              href="tel:+919016885328"
            >
              <button
                className="bg-gray-900 flex text-base text-white  active:bg-red-700  font-bold px-4 py-2.5 min-h-11  rounded-md shadow hover:shadow-lg outline-none focus:outline-none "
                type="button"
                style={{
                  transition: "all .15s ease",
                  background: "green",
                }}   
              >
                <div className="mr-2">
                  <i class="fa-sharp fa-solid fa-phone"> </i>
                </div>
                

                  +91 9016885328
                
              </button></a>
            <button
              className="bg-gray-900 text-base  text-white  active:bg-red-700  font-bold px-4 xl:h-11  rounded-md shadow outline-none focus:outline-none"
              type="button"
              style={{
                transition: "all .15s ease",
                background: "green",
              }}
              onClick={() => history.push("/Contact")}
            >
              GET FREE QUOTE
            </button>
          </div>
        </div>
        <div
          className={
            "items-center bg-black  lg:hidden ml-auto" +
            (navbarOpen ? " block rounded shadow-lg" : " hidden")
          }
          id="example-navbar-warning"
        >
          {navbarOpen == true && (
            <ul className="flex flex-col left-0 top-28 absolute bg-black gap-3 p-4 w-full justify-center items-center">
              <Link to="/Services">
                <li className="flex items-center">
                  <a
                    className={
                      (props.transparent
                        ? "lg:text-white lg:hover:text-gray-300 text-white"
                        : "text-gray-800 hover:text-gray-600") +
                      " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                    }
                  // href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/landing"
                  >
                    <i
                      className={
                        (props.transparent
                          ? "lg:text-gray-300 text-white"
                          : "text-white") +
                        " far fa-file-alt text-lg leading-lg mr-2"
                      }
                    />{" "}
                    Service
                  </a>
                </li>
              </Link>
              <Link to="/AboutUs">
                <li className="flex items-center">
                  <a
                    className={
                      (props.transparent
                        ? "lg:text-white lg:hover:text-gray-300 text-white"
                        : "text-gray-800 hover:text-gray-600") +
                      " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                    }
                  // href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/landing"
                  >
                    <i
                      className={
                        (props.transparent
                          ? "lg:text-gray-300 text-white"
                          : "text-white") +
                        " far fa-file-alt text-lg leading-lg mr-2"
                      }
                    />{" "}
                    About Us
                  </a>
                </li>
              </Link>
              <Link to="/Contact">
                <li className="flex items-center">
                  <a
                    className={
                      (props.transparent
                        ? "lg:text-white lg:hover:text-gray-300 text-white"
                        : "text-white hover:text-gray-600") +
                      " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                    }
                  // href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/landing"
                  >
                    <i
                      className={
                        (props.transparent
                          ? "lg:text-gray-300 text-white"
                          : "text-white") +
                        " far fa-file-alt text-lg leading-lg mr-2"
                      }
                    />{" "}
                    Contact Us
                  </a>
                </li>
              </Link>
              <Link to="/Resources">
                <li className="flex items-center">
                  <a
                    className={
                      (props.transparent
                        ? "lg:text-white lg:hover:text-gray-300 text-white"
                        : "text-white hover:text-gray-600") +
                      " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                    }
                  // href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/landing"
                  >
                    <i
                      className={
                        (props.transparent
                          ? "lg:text-gray-300 text-white"
                          : "text-white") +
                        " far fa-file-alt text-lg leading-lg mr-2"
                      }
                    />{" "}
                    Resources
                  </a>
                </li>
              </Link>
              <li className="flex items-center">
                <a
                  className="text-white hover:text-white font-semibold block p-2 text-sm flex gap-2 items-center hover:underline"
                  href="tel:+919016885328"
                >
                  <i class="fa-sharp fa-solid fa-phone"></i>
                  +91 9016885328
                </a>
              </li>
              <li className="flex items-center pl-1">
                <button
                  className="bg-gray-900 text-sm text-white active:bg-red-700  font-bold p-2  rounded-md shadow hover:shadow-lg outline-none focus:outline-none"
                  type="button"
                  style={{
                    transition: "all .15s ease",
                    background: "green",
                  }}
                  onClick={() => history.push("/Contact")}
                >
                  GET FREE QUOTE
                </button>
              </li>
            </ul>
          )}
          {/* <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            <li className="flex items-center">
              <a
                className={
                  (props.transparent
                    ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                }
                href="#pablo"
              >
                <i
                  className={
                    (props.transparent
                      ? "lg:text-gray-300 text-gray-500"
                      : "text-gray-500") +
                    " fab fa-facebook text-lg leading-lg "
                  }
                />
                <span className="lg:hidden inline-block ml-2">Share</span>
              </a>
            </li>

            <li className="flex items-center">
              <a
                className={
                  (props.transparent
                    ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                }
                href="#pablo"
              >
                <i
                  className={
                    (props.transparent
                      ? "lg:text-gray-300 text-gray-500"
                      : "text-gray-500") +
                    " fab fa-twitter text-lg leading-lg "
                  }
                />
                <span className="lg:hidden inline-block ml-2">Tweet</span>
              </a>
            </li>

            <li className="flex items-center">
              <a
                className={
                  (props.transparent
                    ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                }
                href="#pablo"
              >
                <i
                  className={
                    (props.transparent
                      ? "lg:text-gray-300 text-gray-500"
                      : "text-gray-500") +
                    " fab fa-github text-lg leading-lg "
                  }
                />
                <span className="lg:hidden inline-block ml-2">Star</span>
              </a>
            </li>

            <li className="flex items-center">
              <button
                className={
                  (props.transparent
                    ? "bg-white text-gray-800 active:bg-gray-100"
                    : "bg-pink-500 text-white active:bg-pink-600") +
                  " text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
                }
                type="button"
                style={{ transition: "all .15s ease" }}
              >
                <i className="fas fa-arrow-alt-circle-down"></i> Download
              </button>
            </li>
          </ul> */}
        </div>
      </div>
    </nav>
  );
}
